import MccDecorator, { siteTitle } from '../components/decorators/MccDecorator';
import Link from 'next/link';
import { withUserAuthNCheck } from '../components/utils';
import SearchResults from '../components/search/SearchResults';
import { get } from '../lib/api-service';
import { withSessionSsr } from '../lib/session';
import { useRouter } from 'next/router';
import Error from 'next/error';
import { useUserContext } from '../components/userContext';
import { generateServerErrorMessage } from '../components/utils';

export default function Home({ numFound, results, rows, facets, participants, errorCode }) {
    if (errorCode && errorCode > 404) {
        return (
            <Error
                statusCode={errorCode}
                title={generateServerErrorMessage(errorCode)}
            />
        );
    }
    const router = useRouter();
    const user = useUserContext();
    return withUserAuthNCheck(
        <MccDecorator title={siteTitle}>
            <div className='row'>
                <div className='col-10'>
                    <h1>Dashboard</h1>
                    <p style={{ fontSize: 'large' }}>
                        Welcome to MCC Manager, a tool for adding, editing, deleting, and publishing MCC products. Displaying active products assigned to <strong>{participants}</strong>.
                    </p>
                </div>
                {user && !user.isMccIcpsrArchiveManager && (
                    <div className='col-2'>
                        <Link
                            href='/products/create'
                            passHref>
                            <button className='btn btn-primary'>Create Product</button>
                        </Link>
                    </div>
                )}
            </div>
            {numFound === 0 ? (
                <p>There are currently no active products assigned to you.</p>
            ) : (
                <SearchResults
                    results={results}
                    facets={facets}
                    numFound={numFound}
                    sourcePath={'/'}
                    rows={rows}
                    totalPages={Math.ceil(numFound / rows)}
                />
            )}
        </MccDecorator>,
    );
}

function buildApiPath(query, start, rows, currentUser) {
    let apiPath = `/products?`;
    for (const [key, value] of Object.entries(query)) {
        if (Array.isArray(value)) {
            for (let i = 0; i < value.length; i++) {
                apiPath += key + '=' + encodeURIComponent(value[i]) + '&';
            }
        } else {
            apiPath += key + '=' + encodeURIComponent(value) + '&';
        }
    }
    apiPath += 'start=' + start + '&rows=' + rows;
    apiPath += '&facet.field=program_name_facet';
    apiPath += '&facet.field=study_name_facet';
    apiPath += '&facet.field=topic_facet';
    apiPath += '&facet.field=sector_facet';
    apiPath += '&facet.field=location_facet';
    apiPath += '&facet.field=region_facet';
    apiPath += '&facet.field=location_type_facet';
    apiPath += '&facet.field=environment_facet';
    apiPath += '&facet.field=problem_facet';
    apiPath += '&facet.field=solutions_facet';
    apiPath += '&facet.field=knowledge_product_type_facet';
    apiPath += '&facet.field=evaluation_type_facet';
    apiPath += '&facet.field=rounds_facet';
    apiPath += '&facet.field=study_status_facet';
    apiPath += '&facet.field=program_type_facet';
    apiPath += '&facet.field=status_facet';
    apiPath += '&facet.field=search_category_facet';
    apiPath += '&facet.field=analysis_unit_facet';
    apiPath += '&facet.field=restricted_data_facet';
    apiPath += '&facet.sort=index';
    if (!query.participants) {
        apiPath += `&participants=${currentUser}`;
    }
    return apiPath;
}

export const getServerSideProps = withSessionSsr(async (ctx) => {
    if (!ctx.req.session || !ctx.req.session.user) {
        return {
            props: {
                numFound: 0,
                results: [],
                rows: 0,
                facets: {},
                participants: {},
            },
        };
    }
    const { profile } = ctx.req.session.user;
    const currentUser = profile.email;
    const { rows = '50', page = '1', participants = currentUser } = ctx.query;
    const startIndex = Number(page - 1) * Number(rows) + 1;
    const apiPath = buildApiPath(ctx.query, startIndex - 1, rows, currentUser);
    const { data, errorCode } = await get('maintainer', apiPath);
    return {
        props: {
            numFound: data.numFound ? data.numFound : 0,
            results: data.response ? data.response : [],
            rows: data.responseHeader && data.responseHeader.params && data.responseHeader.params.rows ? data.responseHeader.params.rows : 0,
            facets: data.facet_counts ? data.facet_counts : {},
            participants,
            errorCode,
        },
    };
});
